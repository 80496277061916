@import './main';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: $white;
  background-color: $primary;
  font-family: $fontFamily;
}

#root {
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: $large;
  margin: 0 auto;
  @include respond-to ( $small ) {
    padding: 1em;
  }
}

a {
  color: $secundary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
