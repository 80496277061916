@import './main';

.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5%;
  max-width: $large;
  margin: 0 auto;
  padding: 1.6em 0;
  top: 0;
  @include respond-to ( $large ) {
    padding: 1em 1em *3;
  }
  @include respond-to ( $medium ) {
    padding: 1em;
  }
  @include respond-to ( $small ) {
    padding: 0 ;
  }
  &__logo {
  }
  &__social {
    display: flex;
    list-style: none;
    &__item {
      a {
        color: $white;
        font-size: 1.5em ;
        &:hover {
          color: $secundary;
        }
      }
      &:not( :last-child ) {
        padding-right: 1em;
      }
    }
  }
}