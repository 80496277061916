@import './main';

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em 1em;
  padding-bottom: 12px;
  &__options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &__circle {
      width: 1em;
      height: 1em;
      &:not(:first-child) {
        margin-left: 8px;
      }
      border-radius: 100%;
      background-color: $Dark;
    }
  }
  &__link {
    display: flex;
    width: 100%;
    &__url {
      width: 100%;
      padding: 0 4px;
      margin-left: 4px;
      margin-right: 4px;
      background-color: $Dark;
      border-radius: 4px;
      span {
        font-size: 0.8em;
        color: $lightDark;
        line-height: 1;
      }
      i {
        font-size: 0.8em;
        color: $lightDark;
        margin-right: 4px;
      }
    }
    &__go {
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: $negative;
      }
    }
  }
}