@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

$white     :#ffffff;
$primary   :#0C1B20;
$secundary :#D58A25;
$negative  :#6FC678;
$lightDark :#3A3C3D;
$Dark      :#18191A;
$Gray      :#E5E8E7;

$fontFamily : 'Roboto', sans-serif;

$small : 425px;
$medium: 768px;
$large : 1440px
