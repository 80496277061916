@import './main';
.content {
  position       : relative;
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  height         : 90%;
  &__carousel {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    height         : 100%;
    overflow-x       : hidden;
    &::-webkit-scrollbar {
      width        : 8px;
      height       : 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color  : $lightDark;
      border-radius     : 4px;
      &:hover {
        background      : #b3b3b3;
        box-shadow      : 0 0 2px 1px rgba(0, 0, 0, 0.2);
      }
      &:active {
        background-color: #999999;
      }
    }
    &::-webkit-scrollbar-track {
      background-color  : $lightDark;
    }
    &__item {
      position       : relative;
      display        : flex;
      width          : 50%;
      height         : auto;
      padding        : .8em;
      &:hover {
        .description {
          transform: translateY(0);
          opacity: 1;
        }
      }
      @include respond-to ($medium) {
        padding      : 0;
        width        : 100%;
        height       : auto;
        margin-bottom: 1.6em;
      }
      .title {
        display: block;
        font-size: 1.4em;
        padding-bottom: 0.4em;
      }
      .description {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        font-size: 1em;
        text-transform: capitalize;
        background-color: rgba($secundary,1);
        transform: translateY(100%);
        opacity: 0;
        transition: 0.3s ease;
        @include respond-to ($medium) {
          position: relative;
          width        : 100%;
          background-color: transparent;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
  &__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
  }
}

.DIuaA, .gUWalH:hover, .gUWalH:focus, .kyJvRZ,
.bTybGL {
box-shadow: none!important;
}
.DIuaA {
  background-color: #fff!important;
}

.gUWalH:hover, .gUWalH:focus {
  border: 1px solid #c48f32;
}

.kyJvRZ,
.bTybGL {
  background-color: rgba(255,255,255,0)!important;
}