@import './main';

.window {
  display         : flex;
  flex-direction  : column;
  width           : 100%;
  max-width       : 678px;
  height          : 100vh;
  max-height      : 350px;
  padding-top     : 0;
  padding-left    : 0;
  padding-right   : 0;
  padding-bottom  : 0;
  background-color: $lightDark;
  border-radius   : 4px;
  cursor: pointer;
  overflow: hidden;
  @include box-shadow (10px, 10px, 16px, rgba($Dark, 0.19), false);
  &__view {
    width: 100%;
    height: 100%;
    max-width       : 678px;
max-height      : 350px;
    background-color: $white;
    border: none;  
  }
  @include respond-to ($medium) {
    padding      : 0;
    width        : 100%;
    height       : auto;
    margin-bottom: 1.6em;
  }
}