@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
*{margin:0;padding:0;box-sizing:border-box}body{margin:0;padding:0;color:#fff;background-color:#0c1b20;font-family:"Roboto",sans-serif}#root{position:relative;width:100vw;height:100vh;max-width:1440px;margin:0 auto}@media only screen and (max-width: 425px){#root{padding:1em}}a{color:#d58a25;text-decoration:none}a:hover{text-decoration:underline}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar{position:relative;display:flex;justify-content:space-between;align-items:center;width:100%;height:5%;max-width:1440px;margin:0 auto;padding:1.6em 0;top:0}@media only screen and (max-width: 1440px){.navbar{padding:1em 3em}}@media only screen and (max-width: 768px){.navbar{padding:1em}}@media only screen and (max-width: 425px){.navbar{padding:0}}.navbar__social{display:flex;list-style:none}.navbar__social__item a{color:#fff;font-size:1.5em}.navbar__social__item a:hover{color:#d58a25}.navbar__social__item:not(:last-child){padding-right:1em}
@font-face{font-family:"Bookmark";src:url(/static/media/Bookmark.de467e68.eot);src:url(/static/media/Bookmark.de467e68.eot) format("embedded-opentype"),url(/static/media/Bookmark.e7953836.ttf) format("truetype"),url(/static/media/Bookmark.5c8c5f9d.woff) format("woff"),url(/static/media/Bookmark.9c1d0fc5.svg) format("svg");font-weight:normal;font-style:normal;font-display:block}i{font-family:"Bookmark" !important;speak:never;font-style:normal;font-weight:normal;font-feature-settings:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon-behance:before{content:""}.icon-linkedin:before{content:""}.icon-whatsapp:before{content:""}.icon-arrow-circle-right:before{content:""}.icon-lock:before{content:""}.icon-github-alt:before{content:""}
.content{position:relative;display:flex;justify-content:center;align-items:center;flex-direction:column;width:100%;height:90%}.content__carousel{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center;width:100%;height:100%;overflow-x:hidden}.content__carousel::-webkit-scrollbar{width:8px;height:8px}.content__carousel::-webkit-scrollbar-thumb{background-color:#3a3c3d;border-radius:4px}.content__carousel::-webkit-scrollbar-thumb:hover{background:#b3b3b3;box-shadow:0 0 2px 1px rgba(0,0,0,.2)}.content__carousel::-webkit-scrollbar-thumb:active{background-color:#999}.content__carousel::-webkit-scrollbar-track{background-color:#3a3c3d}.content__carousel__item{position:relative;display:flex;width:50%;height:auto;padding:.8em}.content__carousel__item:hover .description{transform:translateY(0);opacity:1}@media only screen and (max-width: 768px){.content__carousel__item{padding:0;width:100%;height:auto;margin-bottom:1.6em}}.content__carousel__item .title{display:block;font-size:1.4em;padding-bottom:.4em}.content__carousel__item .description{position:absolute;left:0;right:0;bottom:0;display:block;font-size:1em;text-transform:capitalize;background-color:#d58a25;transform:translateY(100%);opacity:0;transition:.3s ease}@media only screen and (max-width: 768px){.content__carousel__item .description{position:relative;width:100%;background-color:transparent;opacity:1;transform:translateY(0)}}.content__contact{display:flex;justify-content:center;align-items:center;width:100%;height:20%}.DIuaA,.gUWalH:hover,.gUWalH:focus,.kyJvRZ,.bTybGL{box-shadow:none !important}.DIuaA{background-color:#fff !important}.gUWalH:hover,.gUWalH:focus{border:1px solid #c48f32}.kyJvRZ,.bTybGL{background-color:rgba(255,255,255,0) !important}
.card{position:relative;width:100%;height:100%;border-radius:4px;overflow:hidden}.title{display:none;font-size:2.5em}.title a{color:#fff}.title a:hover{text-decoration:none}.description{display:none;margin:0;padding:.5em;font-size:1em;text-transform:capitalize}
.window{display:flex;flex-direction:column;width:100%;max-width:678px;height:100vh;max-height:350px;padding-top:0;padding-left:0;padding-right:0;padding-bottom:0;background-color:#3a3c3d;border-radius:4px;cursor:pointer;overflow:hidden;box-shadow:10px 10px 16px rgba(24,25,26,.19)}.window__view{width:100%;height:100%;max-width:678px;max-height:350px;background-color:#fff;border:none}@media only screen and (max-width: 768px){.window{padding:0;width:100%;height:auto;margin-bottom:1.6em}}
.head{display:flex;justify-content:space-between;align-items:center;padding:.8em 1em;padding-bottom:12px}.head__options{display:flex;justify-content:flex-start;align-items:center}.head__options__circle{width:1em;height:1em;border-radius:100%;background-color:#18191a}.head__options__circle:not(:first-child){margin-left:8px}.head__link{display:flex;width:100%}.head__link__url{width:100%;padding:0 4px;margin-left:4px;margin-right:4px;background-color:#18191a;border-radius:4px}.head__link__url span{font-size:.8em;color:#3a3c3d;line-height:1}.head__link__url i{font-size:.8em;color:#3a3c3d;margin-right:4px}.head__link__go{display:flex;justify-content:center;align-items:center}.head__link__go i{color:#6fc678}
.contactBtn{display:flex;justify-content:flex-end;align-items:center;padding:2px;width:68px;max-width:68px;max-height:68px;background-color:#fff;border-radius:2em;transition:all .45s cubic-bezier(0.65, 0, 0.076, 1)}.contactBtn:hover{width:100%;height:100%;max-width:276px;max-height:68px;background-color:#fff;border-radius:4px;border-top-right-radius:2em;border-bottom-right-radius:2em;transition:all infinite cubic-bezier(0.65, 0, 0.076, 1)}.contactBtn:hover .title{display:block;transition:all infinite cubic-bezier(0.65, 0, 0.076, 1)}.contactBtn .title{display:none;width:100%;height:100%;margin-right:2px;padding:4px;background-color:#e5e8e7;font-size:1em;color:#0c1b20;border:1px solid rgba(213,138,37,0);border-radius:4px;resize:none;outline:none;transition:all .45s cubic-bezier(0.75, 0, 0.086, 1)}.contactBtn .btn-whatsapp{padding:.5em;width:64px;height:64px;color:#fff;font-size:2em;background-color:#6fc678;border:2px solid rgba(24,25,26,0);border-radius:100%;cursor:pointer;outline:none;transition:all .45s cubic-bezier(0.65, 0, 0.076, 1)}.contactBtn .btn-whatsapp:hover{border:2px solid rgba(24,25,26,.5)}
.footer{position:relative;display:flex;justify-content:center;align-items:center;height:5%;width:100%}.footer img{margin:0 .5em}.footer a{margin:0 .5em}.footer__p{display:inherit;justify-content:space-between;font-size:.7em}
