@import './main';

.card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.title {
  display: none;
  font-size: 2.5em;
  a {
    color: $white;
    &:hover {
      text-decoration: none;
    }
  }
}

.description {
  display: none;
  margin: 0;
  padding: .5em;
  font-size: 1em;
  text-transform: capitalize;
}