@import './main';

.contactBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2px;
  width: 68px;
  max-width: 68px;
  max-height: 68px;
  background-color: $white;
  border-radius: 2em;
  @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
  &:hover {
    width: 100%;
    height: 100%;
    max-width: 276px;
    max-height: 68px;
    background-color: $white;
    border-radius: 4px;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    @include transition(all, infinite, cubic-bezier(0.65,0,.076,1));
    .title {
      display: block;
      @include transition(all, infinite, cubic-bezier(0.65,0,.076,1));
    }
  }
  .title {
    display: none;
    width: 100%;
    height: 100%;
    margin-right: 2px;
    padding: 4px;
    background-color: $Gray;
    font-size: 1em;
    color: $primary;
    border: 1px solid rgba($secundary, 0);
    border-radius: 4px;
    resize: none;
    outline: none;
    @include transition(all, 0.45s, cubic-bezier(0.75,0,.086,1));
  }
  .btn-whatsapp {
    padding: 0.5em;
    width: 64px;
    height:64px;
    color: $white;
    font-size: 2em;
    background-color: $negative;
    border: 2px solid rgba($Dark, 0);
    border-radius: 100%;
    cursor: pointer;
    outline: none;
    @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
    &:hover {
      border: 2px solid rgba($Dark, 0.5);
    }
  }
}


