.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5%;
  width: 100%;
  img {
    margin: 0 0.5em;
  }
  a {
    margin: 0 0.5em;
  }
  &__p {
    display: inherit;
    justify-content: space-between;
    font-size: 0.7em;
  }
}