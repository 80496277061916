$icomoon-font-family: "Bookmark" !default;
$icomoon-font-path: "fonts" !default;

$icon-behance: "\e900";
$icon-linkedin: "\e901";
$icon-whatsapp: "\e902";
$icon-arrow-circle-right: "\e903";
$icon-lock: "\e904";
$icon-github-alt: "\e905";

